/**
 * 实例化日期（兼容苹果）
 * @param {dateStr} date  时间字符串
 */
export const getDate = dateStr => {
  console.log("🆒 getDate 666", dateStr);
  if (dateStr && typeof dateStr === 'string') {
    let date = '';
    let [Y, M, D, h, m, s] = dateStr.replace(/[^0-9]+/g, ',').split(',');
    h = h || '00';
    m = m || '00';
    s = s || '00';
    if (Y && M && D) date += [Y, M, D].join('/');
    if (h && m && s) date += ' ' + [h, m, s].join(':');
    return new Date(date);
  }
  return new Date();
};

/**
 * 统一时间对象
 * 如果是时间戳或时间字符串，将转成时间对象
 */
export const toDate = date => {
  console.log("🆒 getDate 555", typeof date);
  if (typeof date === 'string') {
    return getDate(date);
  }
  if (typeof date === 'number') {
    return new Date(date);
  }
  return date || new Date();
};

/**
 * @description: 校验是否过期，是否晚于设定时间
 * @param {*} oldTime 传入的时间
 * @param {*} originTime 设定的时间，不传则为当前时间
 * @return {*}
 */
export const expirationValid = (
  oldTime,
  originTime
) => {
  const oldT = toDate(oldTime).getTime();
  const newT = originTime ? toDate(originTime).getTime() : new Date().getTime();
  return oldT >= newT;
};

/**
 * 日期格式化
 * @param {date}    date        时间对象
 * @param {string}  formatStr   格式化字符串
 *
 * 格式化字符串
 * YYYY/yyyy/YY/yy 表示年份
 * MM/M 月份
 * W/w 星期
 * dd/DD/d/D 日期
 * hh/HH/h/H 时间
 * mm/m 分钟
 * ss/SS/s/S 秒
 */
export const formatDate = (date = new Date(), formatStr = 'YYYY-MM-DD') => {
  try {
    date = toDate(date);

    let str = formatStr;
    const Week = ['日', '一', '二', '三', '四', '五', '六'];

    const year = date.getYear() % 100;
    str = str.replace(/yyyy|YYYY/, date.getFullYear());
    str = str.replace(
      /yy|YY/,
      year > 9 ? String(year) : String(year).padStart(2, '0')
    );

    const month = date.getMonth() + 1;
    str = str.replace(
      /MM/,
      month > 9 ? String(month) : String(month).padStart(2, '0')
    );
    str = str.replace(/M/g, month);

    str = str.replace(/w|W/g, Week[date.getDay()]);

    str = str.replace(
      /dd|DD/,
      date.getDate() > 9
        ? String(date.getDate())
        : String(date.getDate()).padStart(2, '0')
    );
    str = str.replace(/d|D/g, date.getDate());

    str = str.replace(
      /hh|HH/,
      date.getHours() > 9
        ? String(date.getHours())
        : String(date.getHours()).padStart(2, '0')
    );
    str = str.replace(/h|H/g, date.getHours());

    str = str.replace(
      /mm/,
      date.getMinutes() > 9
        ? String(date.getMinutes())
        : String(date.getMinutes()).padStart(2, '0')
    );
    str = str.replace(/m/g, date.getMinutes());

    str = str.replace(
      /ss|SS/,
      date.getSeconds() > 9
        ? String(date.getSeconds())
        : String(date.getSeconds()).padStart(2, '0')
    );
    str = str.replace(/s|S/g, date.getSeconds());

    return str;
  } catch (error) {
    console.warn(error, date, formatStr);
  }
  return '';
};

/**
 * 距离现在的时间（抽象语言）
 * @param {Date().getTime()} timestamp  时间戳
 */
export const toNowDate = timestamp => {
  const nowTime = new Date().getTime();
  timestamp = toDate(timestamp).getTime();
  const differ = (nowTime - timestamp) / 1000;
  if (differ < 60) {
    return [differ, 'second'];
  }
  if (differ < 3600) {
    return [parseInt(differ / 60, 10), 'minute'];
  }
  if (differ < 86400) {
    return [parseInt(differ / 3600, 10), 'hour'];
  }
  if (differ < 2592000) {
    return [parseInt(differ / 86400, 10), 'day'];
  }
  if (differ < 31104000) {
    return [parseInt(differ / 2592000, 10), 'month'];
  }
  return [parseInt(differ / 31104000, 10), 'year'];
};

/**
 * 距离现在的完整时间（具体时间）
 * @param {Date().getTime()} timestamp  时间戳
 * @param {string}  formatStr   格式化字符串
 *
 * 格式化字符串
 * Y 表示年份
 * M 月份
 * D 日期
 * hh/h 时间
 * mm/m 分钟
 * ss/s 秒
 */
export const toNowTimeForComplete = (timestamp, formatStr = 'hh,mm,ss') => {
  const nowTime = new Date().getTime();
  timestamp = toDate(timestamp).getTime();
  let differ = parseInt((timestamp - nowTime) / 1000, 10);
  const dateArr = [];

  // 年，按365天计算
  if (formatStr.indexOf('Y') >= 0) {
    formatStr = formatStr.replace('Y', '');
    dateArr.push(String(parseInt(differ / 31536000, 10)));
    differ %= 31536000;
  }
  // 月，按30天计算
  if (formatStr.indexOf('M') >= 0) {
    formatStr = formatStr.replace('M', '');
    dateArr.push(String(parseInt(differ / 2592000, 10)));
    differ %= 2592000;
  }
  // 日
  if (formatStr.indexOf('D') >= 0) {
    formatStr = formatStr.replace('D', '');
    dateArr.push(String(parseInt(differ / 86400, 10)));
    differ %= 86400;
  }
  // 时
  if (formatStr.indexOf('hh') >= 0) {
    formatStr = formatStr.replace('hh', '');
    dateArr.push(String(parseInt(differ / 3600, 10)).padStart(2, '0'));
    differ %= 3600;
  }
  if (formatStr.indexOf('h') >= 0) {
    formatStr = formatStr.replace('h', '');
    dateArr.push(String(parseInt(differ / 3600, 10)));
    differ %= 3600;
  }
  // 分
  if (formatStr.indexOf('mm') >= 0) {
    formatStr = formatStr.replace('mm', '');
    dateArr.push(String(parseInt(differ / 60, 10)).padStart(2, '0'));
    differ %= 60;
  }
  if (formatStr.indexOf('m') >= 0) {
    formatStr = formatStr.replace('m', '');
    dateArr.push(String(parseInt(differ / 60, 10)));
    differ %= 60;
  }
  // 秒
  if (formatStr.indexOf('ss') >= 0) {
    formatStr = formatStr.replace('ss', '');
    dateArr.push(String(differ).padStart(2, '0'));
  }
  if (formatStr.indexOf('s') >= 0) {
    formatStr = formatStr.replace('s', '');
    dateArr.push(String(differ));
  }
  return dateArr;
};
/**
 * 距离现在的简化时间（具体时间）
 * @param {Date().getTime()} timestamp  时间戳
 * @param {string}  startUnit   开始单位
 *
 * 开始单位
 * Y 年份
 * M 月份
 * D 日期
 * h 时间
 * m 分钟
 * s 秒
 */
export const toNowDateForSimplify = (timestamp, startUnit = 'D') => {
  startUnit = 'YMDhms'.substr('YMDhms'.indexOf(startUnit));
  const nowTime = new Date().getTime();
  timestamp = toDate(timestamp).getTime();
  let differ = parseInt((timestamp - nowTime) / 1000, 10);
  const dateArr = [];
  // 年，按365天计算
  if (startUnit.indexOf('Y') >= 0) {
    if (differ >= 31536000) {
      dateArr.push(String(parseInt(differ / 31536000, 10)));
      differ %= 31536000;
    } else {
      dateArr.push('0');
    }
  }
  // 月，按30天计算
  if (startUnit.indexOf('M') >= 0) {
    if (differ >= 2592000) {
      dateArr.push(String(parseInt(differ / 2592000, 10)));
      differ %= 2592000;
    } else {
      dateArr.push('0');
    }
  }
  // 日
  if (startUnit.indexOf('D') >= 0) {
    if (differ >= 86400) {
      dateArr.push(String(parseInt(differ / 86400, 10)));
      differ %= 86400;
    } else {
      dateArr.push('0');
    }
  }
  // 时
  if (startUnit.indexOf('h') >= 0) {
    if (differ >= 3600) {
      dateArr.push(String(parseInt(differ / 3600, 10)));
      differ %= 3600;
    } else {
      dateArr.push('0');
    }
  }
  // 分
  if (startUnit.indexOf('m') >= 0) {
    if (differ >= 60) {
      dateArr.push(String(parseInt(differ / 60, 10)));
      differ %= 60;
    } else {
      dateArr.push('0');
    }
  }
  // 秒
  if (startUnit.indexOf('s') >= 0) {
    dateArr.push(String(differ));
  }
  for (let i = 0; i < dateArr.length; i) {
    if (dateArr[i] === '0') {
      dateArr.shift();
    } else {
      break;
    }
  }
  if (dateArr.length < 1) dateArr.push('0');

  return dateArr.reverse();
};
// export const toNowTime = (timestamp) => {}
/**
 * 获取0时区的GMT时间
 */
export const getZeroGMT = date => {
  const dateStr = String(toDate(date)).split(' ');
  if (dateStr[5].indexOf('+')) {
    return date - parseInt(dateStr[5].split('+')[1], 10) * 36000;
  }
  if (dateStr[5].indexOf('-')) {
    return date + parseInt(dateStr[5].split('-')[1], 10) * 36000;
  }
  return date;
};

/**
 * 获取时间戳，兼容IOS
 */
export const changeTime = dateStr => {
  const changeData = dateStr.replace(/\./g, '/');
  let time = new Date(new Date(changeData)).getTime();
  return time;
};

/**
 * 获取两个时间是否是同一天
 * @param {string} start 开始时间 Date()
 * @param {string}  end 结束时间 Date() 不传则直接为和今天时间比较
 */
export const isSameDayByString = (start, end) => {
  return (
    toDate(start).getYear() === toDate(end).getYear() &&
    toDate(start).getMonth() === toDate(end).getMonth() &&
    toDate(start).getDate() === toDate(end).getDate()
  );
};

/**
 * 获取自然周的全部时间戳
 * @param {string} start 开始时间 Date()
 * @return {Array} Date数组
 */

// delete 联调要删掉
export const getNaturalWeekArrByNow = (date = null) => {
  const naturalWeek = [];
  const today = toDate(date);
  // 获取所在的星期
  const WEEK_NUMBER = [7, 1, 2, 3, 4, 5, 6];
  const todayDay = WEEK_NUMBER[today.getDay()];
  const todayDate = today.getDate();
  const monday = todayDate - todayDay + 1;
  const sunday = todayDate + (7 - todayDay);
  for (let index = monday; index <= sunday; index++) {
    naturalWeek.push(toDate(toDate(date).setDate(index)).setHours(0, 0, 0, 0));
  }
  return naturalWeek;
};

/**
 * 连接两个时间
 * @param {String} start
 * @param {String} end
 * @param {String} startFormat
 * @param {String} endFormate
 * @param {String} content
 * @returns
 */
export function formatRangeDate(
  start,
  end,
  startFormat = 'YYYY-MM-DD',
  endFormate = 'YYYY-MM-DD',
  content = '-'
) {
  isSameDayByString(start, end);
  if (isSameDayByString(start, end)) {
    return `${formatDate(start, startFormat)}${content}${formatDate(
      end,
      endFormate
    )}`;
  } else {
    return `${formatDate(start, startFormat)}${content}${formatDate(
      end,
      startFormat
    )}`;
  }
}

/**
 * 获取当前日期函数
 */
export function getNowFormatDate() {
  const date = new Date();
  const year = date.getFullYear(); //获取完整的年份(4位)
  let month = (date.getMonth() + 1)?.toString(); //获取当前月份(0-11,0代表1月)
  let strDate = date.getDate()?.toString(); // 获取当前日(1-31)
  if (+month < 10) {
    month = `0${month}`; // 如果月份是个位数，在前面补0
  }
  if (+strDate < 10) {
    strDate = `0${strDate}`; // 如果日是个位数，在前面补0
  }
  return `${year}-${month}-${strDate}`;
}

/**
 * 获取某一段时间，输出起止和结束日期
 * @param {Number} offset 天数偏移量，负数为往前，正数为往后
 * @param {Object} origin 参照时间，非必传
 * @param {String} format 时间格式，非必传
*/
export function getOffsetDate(offset, origin = new Date(), format = 'YYYY-MM-DD') {
  // 时间差，毫秒数
  let step = offset * 24 * 60 * 60 * 1000;
  let start = toDate(origin).getTime();
  let end = start + step;
  // 将毫秒数转字符串
  let startTime = formatDate(start, format);
  let endTime = formatDate(end, format);
  const res = {
    startTime: start <= end ? startTime : endTime,
    endTime: start <= end ? endTime : startTime,
  }
  // console.log('🆒 getOffsetDate', res);
  return res;
}
export function getQuarterStartAndEnd(date=new Date()) {
  const month = date.getMonth(); // 获取月份，从0开始
  const year = date.getFullYear(); // 获取年份

  // 季度开始月份
  const startMonth = month;
  // 季度结束月份
  const endMonth = month;
  const format = 'YYYY-MM-DD';

  // 季度开始日期
  const startDate = formatDate((new Date(year, startMonth, 1)),format);
  // 季度结束日期
  const endDate = formatDate((new Date(year, endMonth + 1, 0)),format);

  return [startDate, endDate]
     
  
}